import React from 'react' ;
import './styles/filters.css';
// import item from './item';

import M1 from './content/images/catalog/machines/vending/machine_1.jpg';
// import M2 from './content/images/catalog/machines/vending/machine_2.jpg';
// import M3 from './content/images/catalog/machines/vending/machine_3.jpg';
import M6 from './content/images/catalog/machines/vending/machine_6.jpg';
import M7 from './content/images/catalog/machines/vending/machine_7.jpg';
// import M8 from './content/images/catalog/machines/vending/machine_8.jpg';
// import M9 from './content/images/catalog/machines/vending/machine_9.jpg';
// import M10 from './content/images/catalog/machines/vending/machine_10.jpg';
import M14 from './content/images/catalog/machines/vending/machine_14.jpg';

import M15 from './content/images/catalog/machines/arcade/machine_15.jpg';
import M16 from './content/images/catalog/machines/arcade/machine_16.jpg';
import M17 from './content/images/catalog/machines/arcade/machine_17.jpg';
import M18 from './content/images/catalog/machines/arcade/machine_18.jpg';
import M19 from './content/images/catalog/machines/arcade/machine_19.jpg';
import M20 from './content/images/catalog/machines/arcade/machine_20.jpg';
import M21 from './content/images/catalog/machines/arcade/machine_21.jpg';
import M24 from './content/images/catalog/machines/arcade/machine_24.jpg';
import M25 from './content/images/catalog/machines/arcade/machine_25.jpg';

import M22 from './content/images/catalog/machines/claw/machine_22.jpg';
import M23 from './content/images/catalog/machines/claw/machine_23.jpeg';

import candy1 from './content/images/catalog/machines/candy/c1.jpg';
import candy2 from './content/images/catalog/machines/candy/c2.jpg';
import candy3 from './content/images/catalog/machines/candy/c3.jpg';
import candy4 from './content/images/catalog/machines/candy/c4.jpg';
import candy5 from './content/images/catalog/machines/candy/c5.jpg';
// import candy6 from './content/images/catalog/machines/candy/c6.jpg';
// import candy7 from './content/images/catalog/machines/candy/c7.jpg';
// import candy8 from './content/images/catalog/machines/candy/c8.jpg';
import candy9 from './content/images/catalog/machines/candy/c9.jpg';
// import candy10 from './content/images/catalog/machines/candy/c10.jpg';

const imgs = [{name:"Dixie Narco Bev-Max 559", tag:"Vending Machines", src:M1, features:["H:72''; W:43''; D:32''", "45 Drink Selection"]},
// {name:"Pepsi 12-Beverage", tag:"Vending Machines", src:M2, features:["1", "2"]},  
// {name:"Dixie Narco 501E", tag:"Vending Machines", src:M3, features:["1", "2"]},  
// {name:"Snack & Beverage", tag:"Vending Machines", src:M8, features:["1", "2"]},  
// {name:"Stacked Snack & Beverage", tag:"Vending Machines", src:M9, features:["1", "2"]},  
// {name:"Cold Can Combo", tag:"Vending Machines", src:M10, features:["1", "2"]},  
            // {name:"Beverage Max", tag:"Vending Machines", src:M4},  
            // {name:"Beverage Max", tag:"Vending Machines", src:M5},  
{name:"9 Bevarage w/ Display", tag:"Vending Machines", src:M6, features:["H:72''; W:37''; D:35''", "865lbs", "9 different drink selections"]},  
              {name:"Model 113", tag:"Vending Machines", src:M7, features:["H:72''; W:33.50''; D:35''", "600lbs", "32 item selection"]},  
              // {name:"Beverage Max", tag:"Vending Machines", src:M11},  
              // {name:"Cold Drink Combo", tag:"Vending Machines", src:M12, features:["1", "2"]},  
              // {name:"8 Beverage", tag:"Vending Machines", src:M13, features:["1", "2"]},  

              {name:"Coffee Crane National 634", tag:"Vending Machines", src:M14, features:["H:72''; W:32''; D:28.5''", "475lbs", "1 ground coffee, 2 instant coffees, hot chocolate, French vanilla, cream, sugar and glasses"]},  
              {name:"FAST AND THE FURIOUS DRIVING GAME", tag:"Arcade Machines", src:M15, features:["H:79''; W:33''; D:63''", "675lbs", "Featuring 3 levels of difficulty, 17 vehicles to select, 12 action packed street courses and 20 customization levels"]},  
              {name:"MAXIMUM FORCE SHOOTING GAME", tag:"Arcade Machines", src:M16, features:["H:72''; W:29''; D:34''", "350lbs", "a target shooting game for one or two players that pits a task force against a terrorist group. Take the bad guys out while avoiding innocent bystanders"]},  
              {name:"BIG BUCK HUNTER PRO SHOOTING GAME", tag:"Arcade Machines", src:M17, features:["H:76''; W:30''; D:31''", "420lbs", "New Dual Pump-Action Shotguns, Over 400 Authentic and Unique 3-D Hunting Scenes, Dynamic Enviroments, and MORE!"]},  
              {name:"TARGET TERROR GOLD SHOOTING GAME", tag:"Arcade Machines", src:M18, features:["H:75''; W:29.5''; D:34''", "400lbs", "2 player shooting game that challenges players to defeat terrorist attacks "]},  
              {name:"TEKKEN 5 FIGHTING GAME", tag:"Arcade Machines", src:M19, features:["H:74''; W:36.5''; D:31.5''", "400lbs", "This new game improves on all aspects of Tekken, including the graphics, characters and most importantly the fighting"]},  
              {name:"KILLER INSTINCT 2 FIGHTING GAME", tag:"Arcade Machines", src:M20, features:["H:73''; W:30''; D:40''", "400lbs", "A one- or two-player fighting game that takes place in the past. Choose as one of ten characters and attempt to defeat the evil Gargos."]},  
              {name:"STREET FIGHTER 2 FIGHTING GAME", tag:"Arcade Machines", src:M21, features:["H:70''; W:30''; D:34''", "300lbs", "In Street Fighter II, two characters fight each other in martial arts matches held on several continents. The winner of two out of three rounds moves on to the next stage."]},  
              {name:"Dreamcade® Claw Machine", tag:"Prize Machines", src:M22, features:["H:70''; W:30.25''; D:31''", "220lbs", "Select usable currency, always win mode"]},  
              {name:"Plush Rescue Crane / Claw Machine", tag:"Prize Machines", src:M23, features:["Junior Size: H:75''; W:30''; D:24''; 375lbs", "Standard Size: H:78''; W:32''; D:32''; 395lbs", "Large Size: H:78''; W:42''; D:32''; 415lbs"]},  
             
              {name:"ARCADE 1up GALAGA", tag:"Arcade Machines", src:M24, features:["H:45.8''; W:19''; D:22.75''", "54.5lbs", "2 Games in 1 (Galaga, Galaxian), Authentic arcade controls, Original Artwork, and MORE!"]},  
              {name:"STREET FIGHTER 2", tag:"Arcade Machines", src:M25, features:["H:72''; W:27.75''; D:26''", "54.5lbs", "The Street Fighter 2 Arcade Video one of the best arcade games ever relased not just in the 80's but of all time."]},
              
              {name:"Spiral Gumball Dispensor", tag:"Quarter Machines", src:candy1, features:["H:5ft"]},
              {name:"Rhino Candy Machines", tag:"Quarter Machines", src:candy5, features:["Comes with single, double, or triple units variations"]},
              {name:"ProVending Dispensor", tag:"Quarter Machines", src:candy4, features:["Total of 4 units"]},
              {name:"Toy and Candy Dispensor", tag:"Quarter Machines", src:candy2, features:["Total of 5 units"]},
              {name:"Extra Units Candy and Toy Dispensor", tag:"Quarter Machines", src:candy3, features:["Total of 7 different units!"]},
              {name:"Super Pro Toy Machine", tag:"Quarter Machines", src:candy9, features:["Single or Double Units", "Works best for small toys"]},

             ];


const filters = [
  {name:"Vending Machines", status: false},
  {name:"Arcade Machines", status: false},
  {name:"Prize Machines", status: false},
  {name:"Quarter Machines", status: false}
];

const Filters = ({onClickAll, all, onClick, filters}) =>
  <form class="catalog">
    <ul>
      <li onClick={onClickAll}>
        <input
          className="filterButtons" 
          type="checkbox"
          checked={all}
        />
        <label class="catalog_label" htmlFor="all">All</label>
      </li>
       {filters.map(
        (filter, i)=>
          <li  key={i} data-index={i} onClick={onClick} >
            <input
              className="filterButtons" 
              id={filter.name} 
              type="checkbox" 
              checked={filter.status}
            />
            <label class="catalog_label" htmlFor={filter.name}>{filter.name}</label>
          </li>)}
    </ul>
  </form>

const Cards = ({imgs}) =>
<ul>
    {imgs.map(
      (img, i)=>
      <li key={i}>
        <figure className="flipContainer">
          <div className="inner">
            <div className="front">
              <img className="itemPhoto" src={img.src} alt={img.name}/>
              <figcaption> 
                  <div>{ img.name} </div>
                  {/* <span className="itemSpan">{img.tag}</span> */}
              </figcaption>
            </div>
            <div className="back">
              <h4>Features</h4>
              <ul className="feat">
                {img.features.map(
                  (feature, j)=>
                  <li className="featLi" key={j}>
                  {feature}
                  {/* <a>ts</a> */}
                  </li>)}
              </ul>
            </div>
          </div>
        </figure>
      </li>)}
  </ul>

class filterMachine extends React.Component{
  state ={
    imgs, 
    filters,
    all: true
  };

  setFilter = (e) =>{
    e.preventDefault();
    const { filters, all } = this.state;
    const { index } = e.currentTarget.dataset;
   
    filters[index].status = !filters[index].status;
    this.setState({
      filters
    });
    
    this.updateFilters();
    this.updateImgs();
  }
  
  setAll = () =>{
    const {filters} = this.state;
    
    filters.forEach( 
      filter => {
        filter.status = false;
      }
    );
    
    this.setState({
      all: true,
      filters
    });
  }
  
  updateFilters(){
    const allFiltersTrue = filters.every( filter => filter.status === true);
    const allFiltersFalse = filters.every( filter => filter.status === false);
    
    if(allFiltersTrue||allFiltersFalse){
      this.setAll();
    }else{
      this.setState({
        all: false
      });
    } 
  }
  
  updateImgs() {
    const { filters, all } = this.state;
    let newImgs = [];
    let a = 0;
    
    imgs.forEach((img, imgKey) => { 
      filters.forEach((filter, filterKey)=> {  
        if((img.tag===filter.name)&&(filter.status===true)){
          newImgs[a] = img;
          a++;
        }
      })
    });
        
    this.setState({
      imgs: newImgs
    });
  }

  render(){
    const {filters, all} = this.state;  
    return(
      <div className="browseBody">
        <Filters 
          onClickAll={this.setAll}
          all={all}
          onClick={this.setFilter}
          filters={filters} />
        {(all)?(
            <Cards imgs = {imgs}/>
          ):(
            <Cards imgs = {this.state.imgs}/>
        )}
        
      </div>
    );
  }
}
export default filterMachine;