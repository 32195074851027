import React, { useEffect, useState } from "react";
import './styles/page.css';
// import survey from './repairSurvey'
import { db } from "./firebase/config"
// import { identityOrigin } from 'firebase-tools/lib/api';

const Submitions = () => {
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
  
    // return (
        
    // )
    useEffect(() => {
      const getPostsFromFirebase = [];
      const subscriber = db
        .collection("mainSurveyResponses")
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            getPostsFromFirebase.push({
              ...doc.data(), //spread operator
              key: doc.id, // `id` given to us by Firebase
            });
          });
          setPosts(getPostsFromFirebase);
          setLoading(false);
        });
  
      // return cleanup function
      return () => subscriber();
    }, [loading]); // empty dependencies array => useEffect only called once
        if (loading) {
        return <h1>loading firebase data...</h1>;
        }
        
        return (
        <div className="submitionContainer">
            <h1 className = "submition_title">Main Survey Sumbitions:</h1>
            {/* <br/><br/> */}
            <table className="tg">
                <thead>
                <tr>
                    <th className="tg-0lax">Machines of Interest</th>
                    <th className="tg-0lax">Contact</th>
                    {/* <th className="tg-0pky">Phone</th> */}
                    {/* <td className="tg-0lax">Current Customer</td> */}
                    <th className="tg-0lax">Description of Location</th>
                </tr>
                </thead>
                <tbody>
                    {posts.length > 0 ? (
                posts.map((post) => 
                    <tr>
                        <td key={post.key} className="tg-0lax">
                          <strong>Vending Machines:</strong><br/>
                          {post.beverage && <li id = "mint"><span>Beverage</span></li>}
                          {post.chips && <li id = "mint"><span>chips</span></li>}
                          {post.candy && <li id = "mint"><span>candy</span></li>}
                          {post.granolaBars && <li id = "mint"><span>granolaBars</span></li>}
                          {post.coffee && <li id = "mint"><span>coffee</span></li>}
                          {post.hygenic && <li id = "mint"><span>hygenic</span></li>}
                          <strong>Arcade Machines:</strong><br/>
                          {post.coinpusher && <li id = "mint"><span>coinpusher</span></li>}
                          {post.fighting && <li id = "mint"><span>fighting</span></li>}
                          {post.fps && <li id = "mint"><span>fps</span></li>}
                          {post.driving && <li id = "mint"><span>driving</span></li>}
                          {post.pinball && <li id = "mint"><span>pinball</span></li>}
                          <strong>Key Machines:</strong><br/>
                          {post.claw && <li id = "mint"><span>claw</span></li>}
                          {post.block && <li id = "mint"><span>Stacker</span></li>}
                          {post.key && <li id = "mint"><span>key</span></li>}
                          <strong>Other Machines:</strong><br/>
                          {post.gumball && <li id = "mint"><span>gumball</span></li>}
                          {post.bouncy && <li id = "mint"><span>bouncy</span></li>}
                          {post.toy && <li id = "mint"><span>toy</span></li>}

                        </td>
                        <td key={post.key} className="tg-0lax">Name: {post.name}<br/>
                                                               Email: {post.email}<br/>
                                                               Phone: {post.phone}</td>
                        <td key={post.key} className="tg-0lax">{post.locDesc}</td>
                    </tr> )
                ) : (<h1>no answers yet :(</h1>)}
                    
                </tbody>
            </table>
            
        </div>
        );
  };
  
  export default Submitions;