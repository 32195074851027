import React from 'react' 
import './styles/oneStopVending.css'
// import ReactCurvedText from "react-curved-text"
// import bc_image from "./content/images/other/bc_img.png";
import oneStop_image from "./content/images/logos/oneStopLogoMascots.png";

class oneStopVending extends React.Component {    
    render() {
        return (
            <div class="OneStopCard">
                <div class="OneStopContainer">
                    <div class="OneStop_header">
                    <svg className="oneStopTitle oneStopTopTitle" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox='0 0 540 140' >
                            <text x="50%" y="40%" class="stroke-text outmost">1 STOP VENDING</text>
                            <text x="50%" y="40%"  class="stroke-text middle">1 STOP VENDING</text>
                            <text x="50%" y="40%"  class="stroke-text innermost">1 STOP VENDING</text>
                            <text x="50%" y="80%" class="stroke-text outmost">Services</text>
                            <text x="50%" y="80%" class="stroke-text middle" >Services</text>
                            <text x="50%" y="80%" class="stroke-text innermost">Services</text>
                        </svg>

                    {/* <svg className="oneStopTitle oneStopBottomTitle" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" height="20"> */}
                    {/* </svg> */}
                    </div>

                    <div className="OneStop_image">
                        <img alt="" src={oneStop_image}></img>
                    </div>
                    <div class="OneStop_text">
                        <div className="containerForOneStop_text">
                            <div className="shopHeader">
                                Shop Number:
                            </div>
                            <div className="cardPhoneNumber">
                                <a className="phoneNumberLink" href="tel:9092774802">(909) 277-4802</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default oneStopVending;
