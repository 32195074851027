
import React from 'react' 
import {Switch, NavLink} from 'react-router-dom'
// import logo2 from "./content/images/logos/DV_logo_v6.svg"
import './styles/NavBar.css';

// react allows u to not use "document.getElementById()" BTW
function showNav() {
    var navB = document.getElementById("myNav");
    if (navB.className === "nav" && window.innerWidth <= 800 ) {
        navB.className += " responsive";
    } else {
        navB.className = "nav";
    }
  }


function NavBar(){
    return(
        <div className="nav" id="myNav">

        <div className="imageWrap link">
        <a href="./">
        <svg className="navLogo" alt="logo" version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="auto" height="100%" viewBox="0 0 750.000000 310.000000"
        preserveAspectRatio="xMidYMid meet">
            <g stroke="none" transform="translate(0.000000,310.000000) scale(0.100000,-0.100000)">
            <path d="M1255 3080 c-734 -166 -1251 -844 -1211 -1588 39 -735 546 -1320
            1268 -1464 145 -28 413 -31 553 -5 204 38 407 116 563 216 l82 53 0 54 c0 49
            -10 68 -24 45 -13 -20 -156 -107 -251 -152 -221 -105 -385 -142 -635 -142
            -247 0 -413 37 -627 138 -363 171 -636 481 -763 864 -102 308 -100 637 6 943
            146 421 494 768 912 908 167 56 279 73 472 73 142 0 195 -4 280 -22 139 -29
            259 -70 371 -127 92 -46 224 -127 235 -145 14 -23 24 -4 24 45 l0 54 -82 52
            c-146 94 -329 169 -502 204 -114 24 -561 21 -671 -4z"/>
            <path d="M800 1555 l0 -1215 804 0 c724 0 804 2 810 16 3 9 6 555 6 1215 l0
            1199 -810 0 -810 0 0 -1215z m1120 120 l0 -835 -487 2 -488 3 -3 833 -2 832
            490 0 490 0 0 -835z m370 550 l0 -165 -115 0 -115 0 0 165 0 165 115 0 115 0
            0 -165z m0 -295 l0 -40 -115 0 -115 0 0 40 0 40 115 0 115 0 0 -40z m-612
            -1322 l-3 -63 -242 -3 -243 -2 0 65 0 65 245 0 246 0 -3 -62z"/>
            <path d="M1007 2423 c-4 -3 -7 -10 -7 -15 0 -4 194 -8 430 -8 376 0 430 2 430
            15 0 13 -54 15 -423 15 -233 0 -427 -3 -430 -7z"/>
            <path d="M1040 2194 l0 -115 48 3 47 3 3 113 3 112 -51 0 -50 0 0 -116z"/>
            <path d="M1212 2198 l3 -113 48 -3 47 -3 0 115 0 116 -50 0 -51 0 3 -112z"/>
            <path d="M1390 2194 l0 -115 48 3 47 3 3 113 3 112 -51 0 -50 0 0 -116z"/>
            <path d="M1562 2198 l3 -113 48 -3 47 -3 0 115 0 116 -50 0 -51 0 3 -112z"/>
            <path d="M1732 2198 l3 -113 50 0 50 0 3 113 3 112 -56 0 -56 0 3 -112z"/>
            <path d="M1002 2048 c4 -10 101 -14 431 -16 375 -2 427 0 427 13 0 13 -53 15
            -431 15 -340 0 -430 -3 -427 -12z"/>
            <path d="M1040 1840 l0 -110 50 0 50 0 0 110 0 110 -50 0 -50 0 0 -110z"/>
            <path d="M1210 1840 l0 -110 50 0 50 0 0 110 0 110 -50 0 -50 0 0 -110z"/>
            <path d="M1390 1840 l0 -110 50 0 50 0 0 110 0 110 -50 0 -50 0 0 -110z"/>
            <path d="M1560 1840 l0 -110 50 0 50 0 0 110 0 110 -50 0 -50 0 0 -110z"/>
            <path d="M1730 1840 l0 -110 55 0 55 0 0 110 0 110 -55 0 -55 0 0 -110z"/>
            <path d="M1000 1685 c0 -13 54 -15 430 -15 376 0 430 2 430 15 0 13 -54 15
            -430 15 -376 0 -430 -2 -430 -15z"/>
            <path d="M1040 1485 l0 -115 50 0 50 0 0 115 0 115 -50 0 -50 0 0 -115z"/>
            <path d="M1217 1593 c-4 -3 -7 -55 -7 -115 l0 -108 50 0 50 0 0 115 0 115 -43
            0 c-24 0 -47 -3 -50 -7z"/>
            <path d="M1390 1486 l0 -116 50 0 51 0 -3 113 -3 112 -47 3 -48 3 0 -115z"/>
            <path d="M1560 1485 l0 -115 50 0 50 0 0 115 0 115 -50 0 -50 0 0 -115z"/>
            <path d="M1735 1587 c-3 -7 -4 -58 -3 -113 l3 -99 53 -3 53 -3 -3 113 -3 113
            -48 3 c-33 2 -49 -1 -52 -11z"/>
            <path d="M1002 1318 c4 -10 101 -14 431 -16 375 -2 427 0 427 13 0 13 -53 15
            -431 15 -340 0 -430 -3 -427 -12z"/>
            <path d="M1040 1125 l0 -115 50 0 50 0 0 115 0 115 -50 0 -50 0 0 -115z"/>
            <path d="M1210 1125 l0 -115 50 0 50 0 0 115 0 115 -50 0 -50 0 0 -115z"/>
            <path d="M1390 1125 l0 -115 50 0 50 0 0 115 0 115 -50 0 -50 0 0 -115z"/>
            <path d="M1560 1125 l0 -115 50 0 50 0 0 115 0 115 -50 0 -50 0 0 -115z"/>
            <path d="M1734 1227 c-2 -7 -3 -58 -2 -113 l3 -99 53 -3 52 -3 0 115 0 116
            -50 0 c-33 0 -52 -5 -56 -13z"/>
            <path d="M1000 955 c0 -13 54 -15 430 -15 376 0 430 2 430 15 0 13 -54 15
            -430 15 -376 0 -430 -2 -430 -15z"/>
            <path d="M2530 2265 l0 -475 165 0 c176 0 215 8 263 53 52 48 52 52 52 422 0
            370 0 374 -52 422 -48 45 -87 53 -263 53 l-165 0 0 -475z m255 0 l0 -340 -22
            -3 -23 -3 0 346 0 346 23 -3 22 -3 0 -340z"/>
            <path d="M5100 2640 l0 -80 -40 0 -40 0 0 -55 0 -55 40 0 40 0 0 -254 c0 -139
            5 -277 10 -307 17 -87 29 -93 176 -97 l125 -4 -3 74 -3 73 -40 3 c-22 2 -46
            10 -52 19 -10 12 -13 80 -13 255 l0 238 50 0 50 0 0 55 0 55 -50 0 -50 0 0 80
            0 80 -100 0 -100 0 0 -80z"/>
            <path d="M3807 2564 c-3 -3 -56 -6 -116 -5 l-111 2 0 -386 0 -385 95 0 95 0 0
            303 c0 166 3 312 6 325 5 16 13 22 28 20 21 -3 21 -6 24 -325 l2 -323 100 0
            100 0 0 344 c0 200 -4 355 -10 370 -16 43 -66 66 -141 66 -36 0 -68 -3 -72 -6z"/>
            <path d="M4155 2551 c-73 -33 -80 -48 -83 -182 l-4 -119 88 0 88 0 -69 -43
            c-104 -63 -115 -85 -115 -223 0 -130 7 -156 52 -184 28 -18 45 -20 118 -16 47
            2 129 5 183 5 l97 0 0 338 c0 321 -1 340 -20 371 -32 52 -87 72 -200 72 -71 0
            -105 -5 -135 -19z"/>
            <path d="M4659 2560 c-85 -25 -109 -72 -109 -207 0 -122 11 -143 106 -198 l68
            -40 -87 -3 -87 -3 0 -118 c0 -135 10 -158 84 -192 63 -29 217 -27 276 4 76 38
            85 60 85 197 0 143 -7 155 -114 215 l-71 40 90 3 90 3 0 109 c0 107 -1 110
            -30 143 -16 18 -47 39 -68 45 -44 13 -193 15 -233 2z"/>
            <path d="M3030 2556 c0 -2 29 -122 64 -267 90 -368 119 -506 112 -524 -3 -9
            -18 -15 -40 -15 -60 0 -66 -6 -66 -67 l0 -55 114 4 c136 4 154 14 179 106 17
            62 157 780 157 806 0 14 -15 16 -94 16 -110 0 -94 24 -127 -192 -13 -81 -24
            -146 -27 -144 -3 4 -72 322 -72 333 0 2 -45 3 -100 3 -55 0 -100 -2 -100 -4z"/>
            <path d="M5420 2556 c0 -2 29 -122 64 -267 90 -368 119 -506 112 -524 -3 -9
            -18 -15 -40 -15 -60 0 -66 -6 -66 -67 l0 -55 114 4 c136 4 154 14 179 106 17
            62 157 780 157 806 0 14 -15 16 -94 16 -110 0 -94 24 -127 -192 -13 -81 -24
            -146 -27 -144 -3 4 -72 322 -72 333 0 2 -45 3 -100 3 -55 0 -100 -2 -100 -4z"/>
            <path d="M6923 1256 c-39 -19 -57 -34 -72 -65 -20 -38 -21 -57 -21 -404 0
            -361 0 -364 23 -397 38 -57 79 -74 188 -78 111 -5 155 6 205 50 44 39 54 79
            54 221 l0 117 -100 0 -100 0 0 -109 c0 -123 -4 -141 -31 -141 -18 0 -19 13
            -19 345 0 332 1 345 19 345 26 0 31 -19 31 -116 l0 -84 101 0 102 0 -5 113
            c-5 135 -20 170 -88 205 -37 18 -60 22 -140 22 -79 0 -104 -4 -147 -24z"/>
            <path d="M2514 1233 c3 -21 31 -225 61 -453 31 -228 58 -425 60 -438 5 -22 8
            -22 158 -22 l152 0 59 443 c33 243 62 457 64 475 l4 32 -105 0 c-92 0 -106 -2
            -110 -17 -3 -10 -18 -140 -33 -290 -16 -149 -31 -270 -34 -267 -2 3 -18 132
            -35 287 l-30 282 -108 3 -109 3 6 -38z"/>
            <path d="M4310 1175 c0 -92 -1 -95 -19 -85 -33 17 -153 12 -181 -7 -50 -34
            -49 -29 -50 -370 l0 -321 26 -31 c33 -40 84 -54 165 -47 35 3 108 5 162 5 l97
            -1 0 476 0 476 -100 0 -100 0 0 -95z m-5 -470 c0 -246 -1 -260 -19 -263 -10
            -2 -22 2 -27 10 -10 15 -12 478 -3 503 4 9 15 15 28 13 21 -3 21 -6 21 -263z"/>
            <path d="M4530 1200 l0 -70 100 0 100 0 0 70 0 70 -100 0 -100 0 0 -70z"/>
            <path d="M5970 795 l0 -475 200 0 200 0 0 60 0 60 -90 0 -90 0 0 415 0 415
            -110 0 -110 0 0 -475z"/>
            <path d="M6400 795 l0 -475 200 0 200 0 0 60 0 60 -90 0 -90 0 0 415 0 415
            -110 0 -110 0 0 -475z"/>
            <path d="M3173 1081 c-81 -38 -83 -45 -83 -376 0 -273 1 -292 20 -323 34 -55
            92 -74 213 -70 178 6 217 46 217 221 l0 97 -100 0 -99 0 -3 -93 c-3 -85 -5
            -92 -24 -95 -28 -4 -34 19 -34 126 l0 82 130 0 130 0 0 166 c0 189 -8 219 -69
            256 -31 20 -52 23 -146 26 -93 2 -117 0 -152 -17z"/>
            <path d="M3807 1094 c-3 -3 -56 -6 -116 -5 l-111 2 0 -386 0 -385 95 0 95 0 0
            303 c0 166 3 312 6 325 5 16 13 22 28 20 21 -3 21 -6 24 -325 l2 -323 100 0
            100 0 0 344 c0 200 -4 355 -10 370 -16 43 -66 66 -141 66 -36 0 -68 -3 -72 -6z"/>
            <path d="M4997 1094 c-3 -3 -56 -6 -116 -5 l-111 2 0 -386 0 -385 95 0 95 0 0
            303 c0 166 3 312 6 325 5 16 13 22 28 20 21 -3 21 -6 24 -325 l2 -323 100 0
            100 0 0 344 c0 200 -4 355 -10 370 -16 43 -66 66 -141 66 -36 0 -68 -3 -72 -6z"/>
            <path d="M5314 1090 c-12 -4 -31 -21 -43 -36 -20 -26 -21 -35 -21 -353 l0
            -327 31 -30 c29 -28 34 -29 124 -29 83 0 92 -2 84 -16 -10 -20 -52 -29 -140
            -29 l-69 0 0 -40 0 -40 190 0 189 0 18 33 c16 29 18 72 21 450 l3 417 -90 0
            c-49 0 -92 -4 -96 -9 -3 -6 -19 -4 -40 5 -37 15 -127 18 -161 4z m181 -390 c0
            -251 -1 -265 -19 -268 -10 -2 -22 2 -27 10 -10 15 -12 488 -3 513 4 9 15 15
            28 13 21 -3 21 -6 21 -268z"/>
            <path d="M4530 705 l0 -385 100 0 100 0 0 385 0 385 -100 0 -100 0 0 -385z"/>
            </g>
        </svg>
        </a>
        </div>
        <Switch>
            < NavLink activeClassName="active" onClick={showNav} id="home" className="link navlink"  exact to="/">Home</NavLink>
        </Switch>
        <Switch>
            <NavLink activeClassName="active" onClick={showNav} id="browse" className="link navlink" to="/browse">Browse</NavLink>
        </Switch>
        <Switch>
            <NavLink activeClassName="active" onClick={showNav} id="partners" className="link navlink" to="/partners">Partners</NavLink>
        </Switch>
        <Switch>
            <NavLink activeClassName="active" onClick={showNav} id="contact" className="link navlink" to="/contact">Contact Us</NavLink>
        </Switch>
        {/* <Switch>
            <NavLink activeClassName="active" onClick={showNav} id="repairs" className="link navlink" to="/repairs">Repairs</NavLink>
        </Switch> */}
        <div onClick={showNav} className="link navlink icon">
            <div class="bar1 navlink"></div>
            <div class="bar2 navlink"></div>
            <div class="bar3 navlink"></div>
        </div>
        </div>
    )
}

export default NavBar;