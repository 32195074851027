// Import the functions you need from the SDKs you need
import Firebase from "firebase/compat/app";
import "firebase/compat/auth"
import 'firebase/compat/firestore';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD4gifr3AjltZ79txO59QtDI4819qrHPx8",
  authDomain: "dynasty-vending.firebaseapp.com",
  databaseURL: "https://dynasty-vending-default-rtdb.firebaseio.com",
  projectId: "dynasty-vending",
  storageBucket: "dynasty-vending.appspot.com",
  messagingSenderId: "528568738291",
  appId: "1:528568738291:web:c8d63997fedd70ea76b286",
  measurementId: "G-LC842JY3FH"
};

const firebase = Firebase.initializeApp(firebaseConfig)

const db = firebase.firestore();
// firebase.auth().signInAnonymously();
// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export {firebase, db};