import React from 'react' 
import {BrowserRouter as Router, Switch, NavLink} from 'react-router-dom'
import logo from "./content/images/logos/DV_logo_v0.png"
import './styles/footer.css';
import './styles/font/flaticon.css'


function Footer(){
    return(
        <footer className="Footer_Container">
    
            <div className="Footer_Left">
                <h3>Dynasty <span className="Footer_Span">Vending</span></h3>
                <img alt="logo" src={logo}/>
                <p className="Footer_Links">
                   <span className="nonLink fLink"> Links:</span>
                <Router> 
                    <Switch>
                        < NavLink activeClassName="active" id="home" className="link fLink"  exact to="/">Home</NavLink>
                    </Switch>
                    <Switch>
                        <NavLink activeClassName="active" id="browse" className="link fLink" to="/browse">Browse</NavLink>
                    </Switch>
                    <Switch>
                        <NavLink activeClassName="active" id="partners" className="link fLink" to="/partners">Partners</NavLink>
                    </Switch>
                    <Switch>
                        <NavLink activeClassName="active" id="contact" className="link fLink" to="/contact">Contact Us</NavLink>
                    </Switch>
                    {/* <Switch>
                        <NavLink activeClassName="active" id="repairs" className="link fLink" to="/repairs">Repairs</NavLink>
                    </Switch> */}
                    
                </Router>
                </p>
                <p className="copyRight">&copy;DynastyVending LLC, established 2021</p>

            </div>
            <div className="Footer_Center">
                <h3 className="centerTitle">Contact <span className="Footer_Span">Us</span></h3>
                {/* <div>
                    <h4 className="fa Fa_Map_Maker">PO Box</h4>
                    <p><span>1234 Somewhere Somewhere </span>Ca 92223</p>
                </div> */}

                <div>
                    <h4 className="fa Fa_Phone">Call Us</h4>
                    <p>Rigo Reyes: <span className="phoneNumber">(714) 308-1814</span></p>
                    <p>Teresa Reyes: <span className="phoneNumber">(714) 308-2482</span> </p>
                    {/* <h4 className="fa Fa_Phone">Repair Phone</h4> */}
                    {/* <p>951-###-####</p> */}
                </div>
                <div className="fa Fa_Envelope">
                    <p><a className="email_footer" href="mailto:rigoberto@dynastyvend.com?subject=Emailed From Website">-</a></p>
                </div>
            </div>
            <div className="Footer_Right">
                <h3>About<span className="Footer_Span"> the Page</span></h3>
                <p className="Footer_About">
                    DynastyVending is a Husband & Wife family owned buisness since 2020. Operating with all types of coin operated machines such as
                    arcade games, prize machines, and yes even vending machines. Operating in and around southern california our goal is to bring this
                    buisness into your buisness!
                    <div className="Footer_Icons">
                        <a className="socials" href="https://www.facebook.com/rigoberto.b.reyes" target="_blank" rel="noreferrer"><i className="sm flaticon-facebook"></i><p className="smallText">Facebook</p></a>
                        {/* <a className="socials" href="#"><i className="sm flaticon-twitter"></i><p className="smallText">Twitter</p></a> */}
                        <a className="socials" href="https://www.instagram.com/dynastyvendingllc/"><i className="sm flaticon-instagram"></i><p className="smallText">Instagram</p></a>
                        {/* <a className="socials" href="#"><i className="sm flaticon-whatsapp"></i><p className="smallText">Whatsapp</p></a> */}
                        {/* <a className="socials" href="#"><i className="sm flaticon-linkedin"></i><p className="smallText">Linkedin</p></a> */}
                    </div>
                    <div>Icons made by <a className="creditLink" href="https://www.freepik.com" target="_blank" rel="noreferrer" title="Freepik">Freepik</a> from <a target="_blank" rel="noreferrer" className="creditLink" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                </p>
            </div>

        </footer>

    )
}

export default Footer;