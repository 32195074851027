import React, { useEffect, useState } from "react";
import './styles/page.css';
// import survey from './repairSurvey'
import { db } from "./firebase/config"
// import { identityOrigin } from 'firebase-tools/lib/api';

const Submitions1 = () => {
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
  
    // return (
        
    // )
    useEffect(() => {
      const getPostsFromFirebase = [];
      const subscriber = db
        .collection("RepairSurveyResponses")
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            getPostsFromFirebase.push({
              ...doc.data(), //spread operator
              key: doc.id, // `id` given to us by Firebase
            });
          });
          setPosts(getPostsFromFirebase);
          setLoading(false);
        });
  
      // return cleanup function
      return () => subscriber();
    }, [loading]); // empty dependencies array => useEffect only called once
        if (loading) {
        return <h1>loading firebase data...</h1>;
        }
        
        return (
        <div className="submitionContainer">
            <h1 className = "submition_title">Repair Orders:</h1>
            {/* <br/><br/> */}
            <table className="tg">
                <thead>
                <tr>
                    <th className="tg-0lax">Name</th>
                    <th className="tg-0lax">Email</th>
                    <th className="tg-0pky">Phone</th>
                    {/* <td className="tg-0lax">Current Customer</td> */}
                    <th className="tg-0lax">Description of Issue</th>
                </tr>
                </thead>
                <tbody>
                    {posts.length > 0 ? (
                posts.map((post) => 
                    <tr>
                        <td key={post.key} className="tg-0lax">{post.name}</td>
                        <td key={post.key} className="tg-0lax">{post.email}</td>
                        {/* <td key={post.key} className="tg-0lax">{post.current}</td> */}
                        <td key={post.key} className="tg-0lax">{post.phone}</td>
                        <td key={post.key} className="tg-0lax">{post.desc}</td>
                    </tr> )
                ) : (<h1>no answers yet :(</h1>)}
                    
                </tbody>
            </table>
            
        </div>
        );
  };
  
  export default Submitions1;