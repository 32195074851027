import React from 'react' 
import './styles/contentBubble.css'


// 

class ContentBubble extends React.Component {
    constructor(props) {
        super(props);
        this.contentDesc = this.props.contentDesc;
        this.img = this.props.src;
    }

    render() {
        return (
            <div className="contentRow">
                <img alt="" className="serviceAreaImg" src={this.img}></img>
                <p className="contentText">{this.contentDesc}</p>
            </div>
        );
    }
}
export default ContentBubble;
