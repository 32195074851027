
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './home';
import Browse from './browse';
import Partners from './partners';
import Contact from './contact';
import Submitions1 from './submitions1';
import Submitions2 from './submitions2';

import './styles/themes.css';
import NavBar from './NavBar';
import Footer from './footer';


function App() {
  // const [offsetY, setOffsetY]=useState(0);
  return (
    <div>
      <Router>
        <NavBar/>
        <Route path="/" exact>
          <Home/>
        </Route>
        <Route path="/browse">
          <Browse />
        </Route>
        <Route path="/partners">
          <Partners />
        </Route>
        <Route path="/contact">
          <Contact/>
        </Route>
        <Route path="/submitions">
          <div className="html">
            <Submitions1/>
            <Submitions2/>
          </div>
        </Route>
      </Router>
      {/* <br></br> */}
      <Footer/>
    </div>
  );
}

export default App;
