import React from 'react' ;
import l01 from "./content/images/partner/location1.png"
import l02 from "./content/images/partner/location2.png"
import l03 from "./content/images/partner/location3.png"
import l04 from "./content/images/partner/location4.png"
import './styles/partners.css';




function partners(){
    return(
        <div>
            <div className="partners-html">
                <h1 className="partnerH1">Proud Partners of Dynasty Vending</h1>
                <div class="wrapper">
                    <div class="item">
                        <div class="polaroid">
                            <img src={l01}/>
                        <div class="caption">Location 1: Nicest Customers</div>
                        </div>
                    </div>

                    <div class="item">
                        <div class="polaroid">
                            <img src={l02}/>
                        <div class="caption">Location 2: Best Arcaders</div>
                        </div>
                    </div>

                    <div class="item">
                        <div class="polaroid">
                            <img src={l03}/>
                        <div class="caption">Location 3: they Serve amazing food!</div>
                        </div>
                    </div>

                    <div class="item">
                        <div class="polaroid">
                            <img src={l04}/>
                        <div class="caption">Location 4: Real Go Getters</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <iframe src="https://www.google.com/maps/d/embed?mid=1f8aH0o9wzrfotawlEqN4dtDNTgKSOY4&ehbc=2E312F" width="640" height="480"></iframe> */}
        </div>
    )
}

export default partners;