import React from 'react' 
import './styles/page.css';
// import aManager from "./content/images/people/DannyL.jpg";
// import repair from "./content/images/people/VincentV.jpg";
// import rigo from "./content/images/people/Rigo.jpg";
// import teresa from "./content/images/people/Teresa.jpg";
// import RepairSurvey from "./repairSurvey";
import BuisnessCard from "./businessCard";
import OneStopVending from './oneStopVending';

function contact(){
    return(
    <div className="html">
        <br></br>
        <br></br>
        
        <div className="contactCardContainers">
            {/* <RepairSurvey/> */}
            <BuisnessCard/>
            <OneStopVending/>
            {/* <ContactCard src={rigo} name="Rigo Reyes" jobTitle="Co-Founder" pointOfContact="rigoberto@dynastyvend.com "/> */}
            {/* <ContactCard src={teresa} name="Teresa Reyes" jobTitle="Co-Founder" pointOfContact="example@example.com"/>
            <ContactCard src={repair} name="Vincent Vasquez" jobTitle="Repair Man" pointOfContact="example@example.com"/>
            <ContactCard src={aManager} name="Danny Luna" jobTitle="Assistant Manager" pointOfContact="example@example.com"/> */}
        </div>
        {/* <div className="column">
            <div className="card">
                <img className="potraitPhoto" src={founder} alt="Rigo"/>
                <div className="container">
                    <h2>Rigo Reyes</h2>
                    <p className="title">Founder</p>
                    <p>example@example.com</p>
                </div>
            </div>
        </div> */}

        {/* <div className="column">
            <div className="card">
                <img className="potraitPhoto"  src={founder} alt="Rigo"/>
                <div className="container">
                    <h2>Teresa Reyes</h2>
                    <p className="title">Founder</p>
                    <p>example@example.com</p>
                </div>
            </div>
        </div> */}

        {/* <div className="column">
            <div className="card">
                <img className="potraitPhoto" src={founder} alt="Rigo"/>
                <div className="container">
                    <h2>Repair Man</h2>
                    <p className="title">Repair</p>
                    <p>example@example.com</p>
                </div>
            </div>
        </div> */}
        {/* <div className="column">
            <div className="card">
                <img className="potraitPhoto" src={founder} alt="Rigo"/>
                <div className="container">
                    <h2>Email Our Office</h2>
                    <p className="title">Our Team</p>
                    <p>Dynasty.Vendllc@gmail.com</p>
                </div>
            </div>
        </div> */}
        <br></br>
<hr></hr>
        
    </div>
    )
}

export default contact;