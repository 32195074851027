import React from 'react' ;
// import Tabs from "./Tabs";
// import Item from "./item";
import FilterMachine from "./filterMachine";
import FilterSnacks from "./filterSnacks";
import FilterItems from "./filterItems";
import './styles/page.css';




function browse(){
    return(
        <div className="html">
            {/* <h1>Browse</h1> */}
            {/* <Tabs> */}
                <div className="tabLabel" label="Machines" color="red">
                    
                    <FilterMachine/>
                </div>
                {/* <div className="tabLabel" label="Snacks" color="blue">
                    <FilterSnacks/>
                </div> */}
                {/* <div className="tabLabel" label="Items" color="green">
                    <FilterItems/>
                </div> */}
            {/* </Tabs> */}

        </div>
    )
}

export default browse;