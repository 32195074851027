import React from 'react' 
import './styles/businessCard.css'
import bc_image from "./content/images/other/bc_img.png";

class businessCard extends React.Component {    
    render() {
        return (
            <div class="card">
                <div class="container">
                    <div class="bc_header">
                        <h1>Dynasty Vending LLC.</h1>
                    </div>
                    <div class="bc_image">
                        <img alt="" src={bc_image}></img>
                    </div>
                    <div class="bc_text">
                        <p>Rigo Reyes <a href="tel:7143081814">(714) 308-1814</a></p>
                        <p>Teresa Reyes <a href="tel:7143082482">(714) 308-2482</a></p>
                        <p><a className="email" href="mailto:rigoberto@dynastyvend.com?subject=Emailed From Website">rigoberto@dynastyvend.com</a></p>
                        <ul>
                           <li><span>SODA MACHINES</span></li> 
                           <li><span>SNACK MACHINES</span></li> 
                           <li><span>FREE PLACEMENT</span></li> 
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default businessCard;
