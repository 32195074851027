import React from 'react' ;
import './styles/filters.css';
// import item from './item';

import M1 from './content/images/catalog/machines/vending/machine_1.jpg';

const imgs = [{name:"Beverage Max", tag:"Vending Machines", src:M1},
            //   {name:"Pepsi 12-Beverage", tag:"Vending Machines", src:M2},  
            //   {name:"9 Beverage", tag:"Vending Machines", src:M3},  
            //   // {name:"Beverage Max", tag:"Vending Machines", src:M4},  
            //   // {name:"Beverage Max", tag:"Vending Machines", src:M5},  
            //   {name:"9 Bevarage w/ Display", tag:"Vending Machines", src:M6},  
            //   {name:"Combo Snack", tag:"Vending Machines", src:M7},  
            //   {name:"Snack & Beverage", tag:"Vending Machines", src:M8},  
            //   {name:"Stacked Snack & Beverage", tag:"Vending Machines", src:M9},  
            //   {name:"Cold Can Combo", tag:"Vending Machines", src:M10},  
            //   // {name:"Beverage Max", tag:"Vending Machines", src:M11},  
            //   {name:"Cold Drink Combo", tag:"Vending Machines", src:M12},  
            //   {name:"8 Beverage", tag:"Vending Machines", src:M13},  
            //   {name:"Scott Webb", tag:"Arcade Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/5.jpg?raw=true"},
            //   {name:"Jeffrey Czum", tag:"Arcade Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
            //   {name:"Jeffrey Czum", tag:"Arcade Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
            //   {name:"Jeffrey Czum", tag:"Arcade Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
            //   {name:"Jeffrey Czum", tag:"Arcade Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
            //   {name:"Jeffrey Czum", tag:"Prize Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
            //   {name:"Jeffrey Czum", tag:"Prize Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
            //   {name:"Jeffrey Czum", tag:"Prize Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
            //   {name:"Jeffrey Czum", tag:"Prize Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
            //   {name:"Jeffrey Czum", tag:"Prize Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
            //   {name:"Jeffrey Czum", tag:"Quarter Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
            //   {name:"Jeffrey Czum", tag:"Quarter Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/6.jpg?raw=true"},
            //   {name:"Dominika Roseclay", tag:"Quarter Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/7.jpg?raw=true"},
            //   {name:"Valeria Boltneva", tag:"Quarter Machines", src:"https://github.com/OlgaKoplik/CodePen/blob/master/filterGallery/8.jpg?raw=true"}
];


const filters = [
  {name:"?", status: false},
  {name:"??", status: false},
  {name:"???", status: false},
  {name:"?????", status: false}
];

const Filters = ({onClickAll, all, onClick, filters}) =>
  <form  class="catalog">
    <ul>
      <li onClick={onClickAll}>
        <input 
          type="checkbox"
          className="filterButtons"
          checked={all}
        />
        <label class="catalog_label" htmlFor="all">All</label>
      </li>
       {filters.map(
        (filter, i)=>
          <li  key={i} data-index={i} onClick={onClick} >
            <input
              className="filterButtons" 
              id={filter.name} 
              type="checkbox" 
              checked={filter.status}
            />
            <label class="catalog_label" htmlFor={filter.name}>{filter.name}</label>
          </li>)}
    </ul>
  </form>

const Cards = ({imgs}) =>
  <ul>
    {imgs.map(
      (img, i)=>
      <li  key={i}>
        <figure>
            <img className="itemPhoto" src={img.src} alt={img.name}/>
            <figcaption> 
                <div>{ img.name} </div>
                <span className="itemSpan">{img.tag}</span>
            </figcaption>
            
        </figure>
      </li>)}
  </ul>

class filterItems extends React.Component{
  state ={
    imgs, 
    all: true,
    filters
  };

  setFilter = (e) =>{
    e.preventDefault();
    const { filters, all } = this.state;
    const { index } = e.currentTarget.dataset;
   
    filters[index].status = !filters[index].status;
    this.setState({
      filters
    });
    
    this.updateFilters();
    this.updateImgs();
  }
  
  setAll = () =>{
    const {filters} = this.state;
    
    filters.forEach( 
      filter => {
        filter.status = false;
      }
    );
    
    this.setState({
      all: true,
      filters
    });
  }
  
  updateFilters(){
    const allFiltersTrue = filters.every( filter => filter.status === true);
    const allFiltersFalse = filters.every( filter => filter.status === false);
    
    if(allFiltersTrue||allFiltersFalse){
      this.setAll();
    }else{
      this.setState({
        all: false
      });
    } 
  }
  
  updateImgs() {
    const { filters, all } = this.state;
    let newImgs = [];
    let a = 0;
    
    imgs.forEach((img, imgKey) => { 
      filters.forEach((filter, filterKey)=> {  
        if((img.tag === filter.name)&&(filter.status===true)){
          newImgs[a] = img;
          a++;
        }
      })
    });
        
    this.setState({
      imgs: newImgs
    });
  }

  render(){
    const {filters, all} = this.state;  
    return(
      <div className="browseBody">
        <Filters 
          onClickAll={this.setAll}
          all={all}
          onClick={this.setFilter}
          filters={filters} />
        {(all)?(
            <Cards imgs = {imgs}/>
          ):(
            <Cards imgs = {this.state.imgs}/>
        )}
        
      </div>
    );
  }
}
export default filterItems;