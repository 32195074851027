import React from 'react';
import './styles/page.css';
import MainHero from './mainHero';
import Map from './content/images/other/map.png';
import Install from './content/images/other/install.jpg';
import Measuring from './content/images/other/measuring.jpg';
import Snacks from './content/images/other/snacks.jpg';
import ContentBubble from './ContentBubble';

function home(){
    return(
        <div className="html">
            <MainHero />
            <h1 className="title">Why Dynasty Vending?</h1>
            <p className="subtitle first">Our promise to you is that we will provide quality coin-operated-machines
                                    at no cost to you or your business in a fast timely manner</p>
            {/* <p className="subtitle second">We offer various services to help set us appart from our competitors: </p> */}
            {/* such as free inspections upon request, */}
                                            {/* complete customization of item selection, 100% free Installation, and more! */}
        <div className="contentContainer">
            <ContentBubble src={Measuring} contentDesc="Free site inspections upon request!"/>
            <ContentBubble src={Snacks} contentDesc="Complete Customization of Item Selection!"/>
            <ContentBubble src={Install} contentDesc="100% free Installation!"/>
            <ContentBubble src={Map} contentDesc="Serving San Bernadino County, Los Angeles County, Orange County, & Inland Empire!"/>
        </div>
            {/* <div className="contentRow">
                <img className="serviceAreaImg" src={Measuring}></img>
                <p className="contentText">Free site inspections upon request 
                </p>
            </div> */}
            {/* <div className="contentRow">
                <img className="contentImg itemSelection" src={Snacks}></img>
                <p className="contentText">Complete Customization of Item Selection
                </p>
            </div> */}
            {/* <div className="contentRow">
                <img className="contentImg installation" src={Install}></img>
                <p className="contentText">100% free Installation
                </p>
            </div> */}
            {/* <div className="contentRow">
                <img className="contentImg installation" src={Map}></img>
                <p className="contentText">Serving Orange County, Inland Empire County and Los Angeles County
                </p>
            </div> */}
           
        </div>
    )
}

export default home;