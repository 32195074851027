
import React, { useState } from 'react'
import heroImg from "./content/images/other/snacks-background.jpg"
// import Popup from "./Popup.js"

import {Switch, NavLink} from 'react-router-dom'
// import Questions from "./survey_question"
import './styles/hero.css';


function MainHero() {
    // const [isOpen, setIsOpen] = useState(false);
   
    // const togglePopup = () => {
    //   setIsOpen(!isOpen);
    // }
        return(
            <div className="hero">
                
                <div className="contents">
                    <h2 className="company-name">Dynasty Vending</h2>
                    <h2 className="hook first">Order and Installation 100% free</h2>
                    <h2 className="hook second">Customize selection of snacks, beverages, items and more!</h2>
                    
                    
                    
                        <button type="button" className="mainButton">
                            <Switch>
                                <NavLink className="heroBtnText" to="/contact"><span className="mainButtonSpan">Get in Contact</span></NavLink>
                            </Switch>
                        </button>
                

                </div>
                <img className="heroIMG" src={heroImg} alt="">
                </img>
                
                {/* <Popup/> */}
                
                {/* {isOpen && <Popup
                    content={<> */}
                        {/* <b>Design your Popup</b>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        <button>Test button</button> */}
                        {/* <Questions/> */}
                    {/* </>}
                    handleClose={togglePopup}
                />} */}

                
            </div>
        )
    }


export default MainHero;